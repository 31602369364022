import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Card } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Cards should be used as an alternative to radio buttons to convey top-level selections that are crucial or important to highlight. A user can toggle items on and off to enable or disable the option. Cards should be used sparingly, as they take up a lot of space on a page.
        </p>
        <p>
          If there is more than one card, a card should be selected by default whenever possible.
        </p>
      </>
    );

    var codeString = `
    <Card
      tabIndex={props.index}
      data-testid={props.displayName}
      bg="light"
      style={{ maxWidth: '25rem' }}
      // Code to Toggle Card On and Off
      onClick={() => props.isDisabled ? false : props.toggleCard()}
    >
      <Card.Body>
        // Optional Image
        <img src="https://via.placeholder.com/150" alt="Placeholder Image" />
        // Card Title
        <div className="mt-2">
          <b>Card Title</b>
        </div>
        // Optional Description
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum velit quis accumsan dapibus. Aenean diam diam, condimentum non porttitor et, efficitur et elit. Donec leo purus, elementum sit amet tincidunt et, lobortis nec mi. Integer ultricies pharetra nibh eu porta. </div>
      </Card.Body>
    </Card>
    `;

    var code = (
      <>
        <br />
        <Card
          tabIndex="1"
          data-testid="Card Title"
          bg="light"
          style={{ maxWidth: '25rem' }}
          // onClick={() => props.isDisabled ? false : props.toggleCard()}
        >
          <Card.Body>
            <img src="https://via.placeholder.com/150" alt="Placeholder Image" />
            <div className="mt-2">
              <b>Card Title</b>
            </div>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum velit quis accumsan dapibus. Aenean diam diam, condimentum non porttitor et, efficitur et elit. Donec leo purus, elementum sit amet tincidunt et, lobortis nec mi. Integer ultricies pharetra nibh eu porta. </div>
          </Card.Body>
        </Card>
        <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Cards should have proper semantic structure.</h5>
        <p>When developing cards for use in a form, it is important to include the tab index to indicate in which order the cards should be cycled through when using a screenreader. The tabindex attribute should be used in accordance with with the WebAIM <a href="https://webaim.org/techniques/keyboard/tabindex#:~:text=tabindex%3D%221%22%20(or,a%20link%20on%20the%20page." target="_blank">suggestions on keyboard accessibility</a>.</p>

        <h5>Cards should have sufficient color contrast between disabled, off, and on states.</h5>
        <p>
          When designing or developing togglable cards, disabled, off, and on states for cards should have sufficient color contrast for both the border and the background color. In addition to using color to denote the state, the border should be made thicker to indicate state change. This will allow for redundancy such that colorblind users will still be able to distinguish between types of card states.
        </p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Cards"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
